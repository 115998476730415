

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddProductStandardModal from "@/components/modals/forms/AddProductStandardModal.vue"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, {business_types} from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";


export default defineComponent({

  name: "customers-listing",
  components: {
    ExportCustomerModal, 
    AddProductStandardModal,
  },

  setup() {
    
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);    
    const loadingData = ref<boolean>(true);
    const searchTearm = ref('');

    interface ProductStandard {
      standard_id: number,      
      user: {
        avatar: string;
        name: string;
        group: string;
      },      
      comp_type: string;    
      status: {
        label: string;
        color: string;
      },
      active: {
        label: string;
        color: string;
      },      
      standard_master_name: string,
      standard_no: string,
      standard_title: string,
      comm_no: string,
    }

    var paginationData = ref({})
    
    paginationData.value = {      
          start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1],
        page_select: 1
    }

    const refreshData =() => {      
      paginationData.value['activePage']  = 1;   
      paginationData.value['page_select'] = 1;  
      getProductStandardList(searchTearm.value)
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
      getProductStandardList(searchTearm.value)
      if(paginationData.value['totPage'] == page){
      paginationData.value['page_select'] = page
      }
      if(paginationData.value['activePage'] == 1){
      paginationData.value['page_select'] = page
      }
      
    }
    
    var tableData = ref<Array<ProductStandard>>([]);
    
     const getProductStandardList = async (data) => {
      
      loadingData.value = true;

      try {

        var values = { "standard_id"  :  0, "search_term" : data, "page"  : parseInt(paginationData.value['activePage'])|| parseInt(paginationData.value['page_select']), "records_per_page" : parseInt(paginationData.value['perPage']) }

        await store.dispatch(Actions.CUST_GET_PRODUCT_STANDARD_LIST, values).then(({ data }) => {

          console.log(data);
          
          tableData.value = ([]);
           if(data != 0){
          // set pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          //endpagination
          
          var resultsM = ref<Array<ProductStandard>>([])
          var status_label = ""
          var status_color = ""
          var active_label = ""
          var active_color = ""        

          for (let j = 0; j < data.result_list.length; j++) {

            if (data.result_list[j]['active']){
                active_label = "Yes";
                active_color = "success";                  
            }else{
                active_label = "No";
                active_color = "danger";
            }
            
            resultsM.value = Array({
              standard_id : data.result_list[j]['standard_id'],
              user : {
                group : data.result_list[j]['company_group_type_name'],
                avatar : 'https://cdn.elogicals.com/customers/'+data.result_list[j]['standard_id']+'/logo/'+data.result_list[j]['company_id']+'.png',              
                name : data.result_list[j]['standard_master_name']
              },
              comp_type: data.result_list[j]['company_business_type_name'],   
              status: {
                label: status_label,
                color: status_color
              },
              active: {
                label: active_label,
                color: active_color
              },
              standard_master_name: data.result_list[j]['standard_master_name'],
              standard_no: data.result_list[j]['standard_no'],
              standard_title: data.result_list[j]['standard_title'],
              comm_no: data.result_list[j]['comm_no']
            })

            tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
          }

          loadingData.value = false;
           }
           if(data == 0){
            loadingData.value = false;
            console.log("EMPTY DATA")
            // set pagination
          paginationData.value['total'] = 0;        
          paginationData.value['start'] = 0
          paginationData.value['end'] = 0
          paginationData.value['activePage'] = 1;
          paginationData.value['totPage']  = 1
          paginationData.value['pageRange'] = [1]
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          // paginationData.value['page_select'] = 0
          //endpagination
          }
        }).catch(({ response }) => {

          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };


      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });

      const initCustomers = ref<Array<ProductStandard>>([]);                      

      onMounted( async () => {
        await getProductStandardList(searchTearm.value)
        setCurrentPageBreadcrumbs("Product Standard", []);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);        
        console.log(tableData.value.length);
      });  

      const deleteFewCustomers = () => {
        checkedCompany.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedCompany.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].standard_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
      const searchItems = () => {
            searchTearm.value = search.value;
            getProductStandardList(search.value);

                  console.log(search.value);
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };          
       
    return {
      tableData,      
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,      
      getProductStandardList,       
      paginationData,
      changePageChange,
      refreshData,
      loadingData
    };  

  }  

});




